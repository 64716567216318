import Chart from 'chart.js/auto';

if ($('#salary_chart').length) {
  drawChart($('#salary_chart'), $('#salary_chart_data'));
};

if ($('#project_income_chart').length) {
  drawChart($('#project_income_chart'), $('#project_chart_data'));
}

function drawChart(el, el_data) {
  var el = $(el);
  var el_data = $(el_data);
  var ctx = el.get(0).getContext('2d');

  var myChart = new Chart(ctx, {
    type: 'line',
    data: el_data.data('chart-data'),
    options: {
      customOffset: 1000,
      responsive: true,
      hover: {
        mode: 'nearest',
        intersect: true
      },
    }
  });
}
