import "../stylesheets/application.scss";

import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import "font-awesome/css/font-awesome.css";
import "admin-lte/dist/css/AdminLTE.css";
import "admin-lte/dist/css/skins/_all-skins.css";
import 'jquery-ui';
import 'jquery-ujs';
import 'bootstrap-datepicker';
import 'moment';
import 'select2';
import 'datatables.net';
import 'datatables-bootstrap';
import 'eonasdan-bootstrap-datetimepicker';
import './charts';
import './inventories';
require('admin-lte');

jQuery.noConflict()

$.ajaxSetup({
  statusCode: {
    302: function (response) {
      let redirect_url = response.getResponseHeader('X-Ajax-Redirect-Url');

      if (redirect_url != undefined) {
        window.location.pathname = redirect_url;
      }}
  }
});

$('body').addClass(localStorage.getItem('sidebar-state'));

$('.jdatepicker').attr('autocomplete','off');

$('.jdatepicker').datepicker({
  format: 'yyyy-mm-dd',
  autoclose: true
});

$('.select2').select2({
  theme: "bootstrap"
});

$('.datetimepicker').datetimepicker();

$('.report-date').datepicker().on('changeDate', function(e) {
  const date = e.date;
  window.location.search = `?date%5Bmonth%5D=${date.getMonth()+1}&date%5Byear%5D=${date.getFullYear()}`;
  return false;
});

var activePage = stripTrailingSlash(window.location.pathname);
$('.sidebar-menu li a').each(function () {
  var currentPage = stripTrailingSlash($(this).attr('href'));
  if (activePage == currentPage) {
    $(this).closest('.treeview').addClass('active');
    $(this).parent().addClass('active');
  }
});

function stripTrailingSlash(str) {
  if (str.substr(-1) == '/') { return str.substr(0, str.length - 1); }
  return str;
}

$('.sidebar-toggle').on('click', function () {
  if ($('body').attr('class').indexOf('sidebar-collapse') != -1) {
    localStorage.setItem('sidebar-state', '');
  } else {
    localStorage.setItem('sidebar-state', 'sidebar-collapse');
  }
});

function getUrlVars() {
  let vars = [], hash;
  let search = window.location.search;

  if (search === '') {
    let currentDate = new Date();
    vars['date[month]'] = currentDate.getMonth();
    vars['date[year]'] = currentDate.getFullYear();
  } else {
    var hashes = search.replace('?', '').split('&');
    for (let i = 0; i < hashes.length; i++)
    {
      hash = hashes[i].split('=');
      let val = decodeURIComponent(hash[0]);
      vars.push(val);
      vars[val] = hash[1];
    }
  }
  return vars;
}

$('#send_salary_mail').on('click', function () {
  var userId = $('#month-report').data('userId');
  var query_params = getUrlVars();
  var month = query_params["date[month]"];
  var year = query_params["date[year]"];
  var notify_at = $('#salary_report_notify_at').val();
  var data = {
    user_id: userId,
    date: { year: year, month: month },
    notify_at: notify_at
  }
  if (confirm('Are you sure?')) {
    $.ajax({
      type: "POST",
      url: '/admin/salary_mail',
      data: data,
      dataType: 'json',
      success: function (data) {
        var msg = data['message'];
        if (data['status'] == 'ok') {
          showFlashMessage(msg, 'success', 'Success!');
        } else {
          showFlashMessage(msg, 'danger', 'Error!');
        }
      }
    });
  }
  return false;
});

function showFlashMessage(txt, type, header) {
  var el = '<div class="alert alert-dismissable alert-' + type + '"><button aria-hidden="true" class="close" data-dismiss="alert" type="button">×</button><h4><i class="icon fa fa-check"></i>' + header + '</h4><div id="flash_notice">' + txt + '</div></div>';
  $('section.content').prepend(el);
}

$(document).on('click', '#toggle_report_rows', function(e) {
  if ($('table#month_info tr.check').first().is(':visible') && $('table#month_info tr.ok').first().is(':visible')) {
    $('#toggle_report_rows').text('Show correct reports');
    $('table#month_info tr.ok').hide();
  } else {
    $('#toggle_report_rows').text('Hide correct reports');
    $('table#month_info tr.ok').show();
  }
});
