$(document).ready(() => {
  $(document).on('select2:selecting', '.select-holder', function(e) {
    const user_id = e.params.args.data.id;
    const inventory_id = e.target.id;

    $.ajax({
      type: 'PUT',
      url: '/admin/inventories/' + inventory_id + '/holders/' + user_id,
      dataType: 'json',
      success: function(data) { replaceSelect(data) }
    });
    return false;
  });

  $(document).on('click', '.btn-destroy-holder', function(e) {
    if (confirm('Are you sure you want to delete inventory holder?')) {
      const user_id = $(e.target).data('userId');
      const inventory_id = $(e.target).data('inventoryId');

      $.ajax({
        type: 'DELETE',
        url: '/admin/inventories/' + inventory_id + '/holders/' + user_id,
        dataType: 'json',
        success: function(data) { replaceInfoBox(data) }
      });
      return false;
    }
  });
})

function replaceInfoBox(data) {
  let currentInfoBox = getCurrentInfoBox(data.inventory_id);
  const newSelect = getNewSelect(data.inventory_id, data.users);

  currentInfoBox.replaceWith(newSelect);

  $('.select2').select2({
    theme: "bootstrap"
  });
}

function replaceSelect(data) {
  let currentSelect = getCurrentSelect(data.inventory_id);
  const newInfoBox = getNewInfobox(data.inventory_id, data.user_name, data.user_id);

  if (currentSelect.children('select').data().hasOwnProperty('select2')) {
    currentSelect.children('select').select2('destroy');
  }
  currentSelect.replaceWith(newInfoBox);
}

const getCurrentSelect = (inventoryId) => $(`.users_list[data-inventory-id='${inventoryId}']`);

const getCurrentInfoBox = (inventoryId) => $(`.holder[data-inventory-id='${inventoryId}']`);

function getNewSelect(inventoryId, users) {
  let sel = $("<select class='select2 form-control select-holder' id=" + inventoryId + "></select>");
  sel.append("<option>Select User</option>");
  users.forEach(function(user) {
    let option = $('<option value=' + user.id + '>' + user.name + '</option>');
    sel.append(option);
  });
  let div = $("<div class='users_list' data-inventory-id='" + inventoryId + "'></div>");
  div.append(sel);
  return div;
}

function getNewInfobox(inventoryId, userName, userId) {
  let btn = $("<div class='btn btn-sm btn-danger btn-destroy-holder' data-inventory-id='" + inventoryId +"' data-user-id='" + userId + "'></div>");
  let i = $("<i class='fa fa-times' data-inventory-id=" + inventoryId + ' data-user-id=' + userId + '></i>');
  let div = $("<div class='holder btn-flex' data-inventory-id='" + inventoryId + "'>" + userName + '</div>');
  btn.append(i);
  div.append(btn);
  return div;
}
